/*--------- BOTTONI --------------*/


.offer1 .btn .btn-primary{
  background-color: none !important;
}


/*------------------------------*/

.offer1{
    background-color: #fff;
    
}



.offer1 .modal-backdrop{
    background-color: #111 !important;
}

.offer1 .modal-content{
    border:none !important;
}

.offer1 .titolone{
    color: hsl(207, 55%, 49%);
    text-align: center;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 32px;
    margin-top: 10px;
    text-transform: uppercase;
}

.offer1 .lpbody .s1 img{
    width: 200px;
}


.offer1 h4{
    margin:0;
    color:#800000;
    font-size: 18px;
}

.offer1 .customColor1{
    color:#979797;
    font-size: 15px;
    display: block;
}

.offer1.modale_landing button.btn {
    width: 50%;
    float: left;
    margin: 0;
    font-size: 1em;
  }

.offer1 .contenitoreDomande{
    border: none;
    border-radius: 50%;
}

.offer1 .footerDomande{
    margin-top:50px;
    width: 100%;
    background-color: rgb(186, 186, 186);
    bottom: 0;
    left: 0;
    padding: 10px;
    text-align: center;
    font-size: 0.8em;
    line-height: 1em;
    color:#fff
}

.offer1 .footerDomande ul{
    margin:10px 0 0 0 !important;
}

.offer1 .footerDomande ul li{
    display: inline;
    
}

.offer1 .ultimostep .titolone{
   color:#003366;
}

.offer1 .footerDomande ul li a {
    color:#fff
}
.offer1 .footerDomande span{
    margin:20px;
}


.offer1 .modale_landing .modal-footer .buttonStepFinale .btn-primary{
    background-color: #800;
}


.offer1 .modale_landing .modal-footer .btn-primary {
    background-color: hsl(207, 55%, 49%);
}

.offer1 .lpbody p {
    font-size: 17px;
    margin: 10px 0 ;
    line-height: 1.2em;
}

.offer1 .react-cookie-law-container{
    -webkit-box-shadow: 5px 5px 10px -5px #000000; 
box-shadow: 5px 5px 10px -5px #000000;
position: fixed;
bottom: 0;
left: 0;
z-index: 2147483645;
box-sizing: border-box;
width: 100%;
max-width: 100% !important;
background-color: #c9c9c9;
}


.offer1 .react-cookie-law-msg{
    margin-top: 20px;
}

.offer1 span.chiudi::after{
    content: "X";
    position: absolute;
    top:10px;
    right:10px;
    font-size: 1.5em;
}


.offer1 .react-cookie-law-option-wrapper label{
    color:#111 !important;
}

.offer1 button.react-cookie-law-manage-btn{
   

  padding: 9px 13px !important; 
  border: 2px solid #3A4649 !important;
  background-color: transparent !important;
  color: transparent !important;
  box-sizing: border-box !important;
display: inline-block !important;
min-width: 164px !important;
padding: 11px 13px !important;
  
border-radius: 2px !important;

background-color: transparent !important;
letter-spacing:normal;
color: #111 !important;
text-decoration: none !important;
text-align: center !important;
font-family: "CeraPRO-Regular", sans-serif, arial !important;
font-weight: normal !important;
font-size: 12px !important;
line-height: 20px !important;
}


.offer1 button.react-cookie-law-save-btn{
   

  padding: 9px 13px !important; 
  border: 2px solid #3A4649 !important;
  background-color: transparent !important;
  color: transparent !important;
  box-sizing: border-box !important;
display: inline-block !important;
min-width: 164px !important;
padding: 11px 13px !important;
  
border-radius: 2px !important;

background-color: transparent !important;
letter-spacing:normal;
color: #111 !important;
text-decoration: none !important;
text-align: center !important;
font-family: "CeraPRO-Regular", sans-serif, arial !important;
font-weight: normal !important;
font-size: 12px !important;
line-height: 20px !important;
}


.offer1 button.react-cookie-law-accept-btn{
    padding: 9px 13px !important; 
    border: 2px solid #3A4649 !important;
    background-color: transparent !important;
    color: #2CE080 !important;
    box-sizing: border-box !important;
  display: inline-block !important;
  min-width: 164px !important;
  padding: 11px 13px !important;
    letter-spacing:normal;
  border-radius: 2px !important;
  
  background-color: #2CE080 !important;
   
  color: #FFF !important;
  text-decoration: none !important;
  text-align: center !important;
  font-family: "CeraPRO-Regular", sans-serif, arial !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 20px !important;
}


.offer1 .react-cookie-law-msg{
    padding: 10px !important;
}


.offer1 .domandaCheckbox li{
    list-style-type: none;
    text-align: left;
}

.offer1 .domandaCheckbox span{
    margin-left: 5px;
    font-size: 18px;
}

.offer1 h2.titolo{
    margin-top: 10px;
    font-size: 1.3em;
    color: #903C38;
    line-height: 27px;
}

.offer1 h2.titolettoSelezioni{
    margin-top: 1px;
    font-size: 1.2em;
    line-height: 1.2;
    
}

.offer1 .descrizioneSelezioni {
    display: block;
    font-weight: 200;
    color: #111;
    background-color: #fff;
  }


  .offer1 .lpbody img.selezione{
    width: 50px;
  }


  .offer1 .grigino{
    color:rgb(122, 124, 122);
    text-transform: uppercase;
    
    display: block;
  }


.offer1 h3{
    font-weight: 200;
}

.offer1 .contieniPulsanti button{
    color:#fff
}

.offer1 h3.sottotitolettoSelezioni{
    margin-top: 10px;
    font-size: 1em;
    line-height: 1.3em;
}


.offer1 h3.titolo_piccolo{
    margin-top: 10px;
    font-size: 1.6em;
    line-height: 1.3em;
}

.offer1 h4.ultimostep{
    margin-top: 10px;
    font-size: 1.2em;
    line-height: 1.3em;
}

.ultimostep ul li {
    text-align: left;
  }

.ultimostep ul {
   margin: 20px 0 20px 20px
  }

.offer1 .domandaCheckbox input[type=checkbox]{
    width: 22px;
  height: 22px;
  margin: 0;
}

.offer1 .mt10{
    display: block;
    margin-top: 10px;
}

.offer1 .lpbody img {
    width: 20%;
    text-align: left;
    margin: 0;
    max-width: 100%;
}

.offer1 .elenco_puntato{
    color: #800000
}

.offer1 .modale_landing .modal-footer .btn-secondary{
    background-color: hsl(207, 55%, 49%);
}

.offer1 .gialdo{
    background-color: #e2f100;
}

.offer1 .grenne{
    background-color: #2fe00e;
}

.offer1 .contieniPulsanti{
  text-align: center;
}

.offer1 .errore{
  color: #ff4e4e;
  font-weight: bold;
}

.offer1 .unico{
    text-align: center;
    display: inline-block;
    color: #ffffff;
  background-color: #903C38;
    background-image: linear-gradient(to bottom,rgba(255,255,255,.2) 0,rgba(255,255,255,.01) 100%);
    border-radius: 40px 40px;
    font-size: 1em;
    width: 80%;
}

.offer1 .unico .btn:hover, button:hover{
  background-color: #903C38 !important;
}

.offer1 .modal-content {
    border-radius: 0.5em !important;
    
}

.offer1 select{
    display: inline-block;
    width: 80%;
    margin: 10px 0;
}

.offer1 input[type="text"],.offer1 input[type="search"],.offer1 input[type="email"],.offer1 input[type="password"]{
  display: inline-block !important;
  width: 80%;
  margin: 10px 0;
}

.offer1 input[type="search"]{
 margin-bottom: 0px !important;
 
}

.offer1 input[type="search"]:focus{
  background-color: #EDEDED !important;
  color:#3A4649
}

.offer1 input[type="email"]:focus{
  background-color: #EDEDED !important;
  color:#3A4649
}

.offer1 input[type="input"]:focus{
  background-color: #EDEDED !important;
  color:#3A4649
}

.offer1 input[type="password"]:focus{
  background-color: #EDEDED !important;
  color:#3A4649
}

.offer1 .suggestions{
  background-color: #d8d6d6;
  width: 80%;
  border: 1px solid #a6a4a4;

}

.offer1 label.terms{
  line-height: 1em;
  font-size: 0.7em;
  font-weight: inherit;
}



.offer1 .terms input[type="checkbox"]{
  margin-right: 6px;
}

.offer1 label { 
  display: block; 
} 

.suggestions div:hover{ background-color:#f38d96 }


hr{
  margin: 20px 0 !important;
}

@media (max-width: 576px) {
    
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0 3.75rem !important;
    pointer-events: none;

  }

  .offer1 .lpbody img {
    width: 41%;

  }

  .offer1 h2.titolettoSelezioni {
    font-size: 1em;
  }

  .offer1 h2.titolo {
    margin-top: 10px;
    font-size: 1em;
    color: #903C38;
    font-size: 1em;
    line-height: 19px;
  }

}


@media (min-width: 576px) {
    
    .modal-dialog {
        position: relative;
        width: auto;
        margin: 3.75rem auto !important;
        pointer-events: none;
      }
    
    }