.lp5{
    background-color: #fff;
    
}

.lp5 .modal-backdrop{
    background-color: #fff !important;
}

.lp5 .modal-body{
    background-color: #fff !important;
}

.lp5 .modal-content{
    border:none !important;
    background-color: #111;
}

.lp5 .modal-dialog{
    background-color: #111;
}

.lp5 .modal-footer{
    background-color: #fff;
}

.lp5 .titolone{
    color: hsl(207, 55%, 49%);
    text-align: center;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 32px;
    margin-top: 10px;
    text-transform: uppercase;
    line-height: 1em;
}

.lp5 .modal{
background-color: #111;
}




.lp5 .lpbody .s1 img{
    width: 200px;
}


.lp5 h4{
    margin:0;
    color:#800000;
    font-size: 18px;
}

.lp5 .customColor1{
    color:#979797;
    font-size: 15px;
    display: block;
}

.lp5.modale_landing button.btn {
    width: 50%;
    float: left;
    margin: 0;
    font-size: 1em;
  }

.lp5 .contenitoreDomande{
    border: 4px solid hsl(207, 55%, 49%);
}

.lp5 .footerDomande{
    margin-top:50px;
    width: 100%;
    background-color: rgb(186, 186, 186);
    bottom: 0;
    left: 0;
    padding: 10px;
    text-align: center;
    font-size: 0.8em;
    line-height: 1em;
    color:#fff
}

.lp5 .footerDomande ul{
    margin:10px 0 0 0 !important;
}

.lp5 .footerDomande ul li{
    display: inline;
    
}

.lp5 .ultimostep .titolone{
   color:#003366;
}

.lp5 .footerDomande ul li a {
    color:#fff
}
.lp5 .footerDomande span{
    margin:20px;
}


.lp5 .modale_landing .modal-footer .buttonStepFinale .btn-primary{
    background-color: #800;
}


.lp5 .modale_landing .modal-footer .btn-primary {
    background-color: hsl(207, 55%, 49%);
}

.lp5 .lpbody p {
    font-size: 17px;
    margin: 10px 0 ;
    line-height: 1.2em;
}

.lp5 .react-cookie-law-container{
    -webkit-box-shadow: 5px 5px 10px -5px #000000; 
box-shadow: 5px 5px 10px -5px #000000;
position: fixed;
bottom: 0;
left: 0;
z-index: 2147483645;
box-sizing: border-box;
width: 100%;
max-width: 100% !important;
background-color: #c9c9c9;
}


.lp5 .react-cookie-law-msg{
    margin-top: 20px;
}

.lp5 span.chiudi::after{
    content: "X";
    position: absolute;
    top:10px;
    right:10px;
    font-size: 1.5em;
}


.lp5 .react-cookie-law-option-wrapper label{
    color:#111 !important;
}

.lp5 button.react-cookie-law-manage-btn{
   

  padding: 9px 13px !important; 
  border: 2px solid #3A4649 !important;
  background-color: transparent !important;
  color: transparent !important;
  box-sizing: border-box !important;
display: inline-block !important;
min-width: 164px !important;
padding: 11px 13px !important;
  
border-radius: 2px !important;

background-color: transparent !important;
letter-spacing:normal;
color: #111 !important;
text-decoration: none !important;
text-align: center !important;
font-family: "CeraPRO-Regular", sans-serif, arial !important;
font-weight: normal !important;
font-size: 12px !important;
line-height: 20px !important;
}


.lp5 button.react-cookie-law-save-btn{
   

  padding: 9px 13px !important; 
  border: 2px solid #3A4649 !important;
  background-color: transparent !important;
  color: transparent !important;
  box-sizing: border-box !important;
display: inline-block !important;
min-width: 164px !important;
padding: 11px 13px !important;
  
border-radius: 2px !important;

background-color: transparent !important;
letter-spacing:normal;
color: #111 !important;
text-decoration: none !important;
text-align: center !important;
font-family: "CeraPRO-Regular", sans-serif, arial !important;
font-weight: normal !important;
font-size: 12px !important;
line-height: 20px !important;
}


.lp5 button.react-cookie-law-accept-btn{
    padding: 9px 13px !important; 
    border: 2px solid #3A4649 !important;
    background-color: transparent !important;
    color: #2CE080 !important;
    box-sizing: border-box !important;
  display: inline-block !important;
  min-width: 164px !important;
  padding: 11px 13px !important;
    letter-spacing:normal;
  border-radius: 2px !important;
  
  background-color: #2CE080 !important;
   
  color: #FFF !important;
  text-decoration: none !important;
  text-align: center !important;
  font-family: "CeraPRO-Regular", sans-serif, arial !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 20px !important;
}


.lp5 .react-cookie-law-msg{
    padding: 10px !important;
}


.lp5 .domandaCheckbox li{
    list-style-type: none;
    text-align: left;
}

.lp5 .customColor1{
    margin-top: 15px;
}

.lp5 .domandaCheckbox span{
    margin-left: 5px;
    font-size: 18px;
    margin-top: 27px;
  font-weight: 600;
}

.lp5 h2.titolo{
    margin-top: 10px;
    font-size: 1.3em;
}

.lp5 h2.titolettoSelezioni{
    margin-top: 1px;
    font-size: 1.2em;
    line-height: 1.2;
    
}

.lp5 .descrizioneSelezioni {
    display: block;
    font-weight: 200;
    color: #111;
    background-color: #fff;
  }


  .lp5 .lpbody img.selezione{
    width: 50px;
  }


  .lp5 .grigino{
    color:rgb(122, 124, 122);
    text-transform: uppercase;
    
    display: block;
  }


.lp5 h3{
    font-weight: 200;
}

.lp5 .contieniPulsanti button{
    color:#fff
}

.lp5 h3.sottotitolettoSelezioni{
    margin-top: 10px;
    font-size: 1em;
    line-height: 1.3em;
    font-weight: 500;
}


.lp5 h3.titolo_piccolo{
    margin-top: 10px;
    font-size: 1.6em;
    line-height: 1.3em;
}

.lp5 h4.ultimostep{
    margin-top: 10px;
    font-size: 1.2em;
    line-height: 1.3em;
}

.ultimostep ul li {
    text-align: left;
  }

.ultimostep ul {
   margin: 20px 0 20px 20px
  }

.lp5 .domandaCheckbox input[type=checkbox]{
    width: 22px;
  height: 22px;
  margin: 0;
}

.lp5 .mt10{
    display: block;
    margin-top: 10px;
}

.lp5 .lpbody img {
    width: 60%;
    text-align: left;
    margin: 0;
    max-width: 100%;
}

.lp5 .elenco_puntato{
    color: #800000
}

.lp5 .modale_landing .modal-footer .btn-secondary{
    background-color: hsl(207, 55%, 49%);
}

.lp5 .gialdo{
    background-color: #e2f100;
}

.lp5 .grenne{
    background-color: #2fe00e;
}

.lp5 .unico{
    text-align: center;
    display: block;
background-color: #3884c2;
color: #fff;
font-size: 1em;
}



@media (max-width: 576px) {
    .lp5 .modal-dialog {
        margin: 0 1.75rem !important;

    }
}